import { InfoRow } from "../../../components/InfoRow/InfoRow";
import { ApplicantInfoContainer, ApplicantName, ApplicantNameSpan } from "./style";
import { defaultNoDataText, type ApplicantInfoProps } from "./types";

export const ApplicantInfo = ({ flow, info, isMainApplicant, hasCoApplicant }: ApplicantInfoProps) => {
  const applicant = isMainApplicant ? flow?.data?.agentApiApplication?.applicant : flow?.data?.agentApiApplication?.coapplicant;
  const applicantText = isMainApplicant ? " (Main applicant)" : " (Co-applicant)";
  const email = applicant?.emailAddress || defaultNoDataText;
  const phone = applicant?.mobileNumber || defaultNoDataText;
  const name = `${applicant?.firstName} ${applicant?.lastName}`;

  const emailMatch = info.email.includes(email);
  const phoneMatch = info.phone.includes(phone);

  return (
    <ApplicantInfoContainer>
      {hasCoApplicant && <ApplicantName>{name}<ApplicantNameSpan>{applicantText}</ApplicantNameSpan> </ApplicantName>}
      <InfoRow title="Email address" value={email} hasMatch={emailMatch} hasPadding={true} />
      <InfoRow title="Phone number" value={phone} hasMatch={phoneMatch} hasPadding={true} />
    </ApplicantInfoContainer>
  )
}