
import { Document, Page, pdfjs, } from 'react-pdf';
import type { PdfPageProps } from './types';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.mjs',
  import.meta.url
).toString()

const PdfPage = ({ pdfPath, scale, width }: PdfPageProps) => {


  return (
    <Document
      file={pdfPath}
      className={'pdf-viewer'}
    >
      <Page
        scale={scale}
        width={width}
        pageNumber={1}
        renderTextLayer={false}
        renderAnnotationLayer={false}
      />
    </Document >
  )
}

export default PdfPage;
