import React, { useState } from "react";
import {
	DecisionSectionWrapper,
	InfoSectionWrapper,
	DecisionSectionDivWrapper,
} from "../styles";
import {
	UsertaskButtons,
	UsertaskApplicantInfo,
	UsertaskTextField,
	Slider,
	UsertaskNumberInput,
} from "../UsertaskComponents";
import { formatCurrency } from "../../../utils/helperFunctions";
import { Icons } from "@stacc/flow-ui-components"
import CommonUserTask from "../CommonUserTask";
import styled from "styled-components";

const ChangeLoanAmount = (flow: any) => {
	const data = flow.flow?.data;
	const applicant = data?.agentApiApplication?.applicant;
	const maxAmount = data?.offer?.maxLoanAmount;
	const minAmount = data?.offer?.minLoanAmount;
	const oldLoanAmount = data.offer?.finalLoanAmount;
	const insuranceSelected = data?.offer?.insuranceSelected;
	const interestRate = insuranceSelected
		? data.offer?.interestRateWithInsurance
		: data.offer?.interestRate;

	const [updatedComment, setUpdatedComment] = useState(
		flow.task?.data?.updatedComment ?? null,
	);
	const [newLoanAmount, setNewLoanAmount] = useState(
		flow.task?.data?.newLoanAmount ?? oldLoanAmount,
	);
	const [error, setError] = useState<string | null>(null);

	const canComplete = newLoanAmount || updatedComment;

	const validate = () => {
		const isValid = true;

		if (!maxAmount || !minAmount || !oldLoanAmount) {
			setError(
				"No upsell / minimum amount available on this application. Not possible to adjust the loan amount.",
			);
			return !isValid;
		}

		if (newLoanAmount !== null && newLoanAmount !== undefined) {
			// Regex for numbers only
			const numbersOnly = /^[0-9]+$/;
			if (!numbersOnly.test(newLoanAmount)) {
				setError("Invalid loan amount.");
				return !isValid;
			}

			// check if the amount exceeds the allowed max amount
			if (Number(newLoanAmount) > Number(maxAmount)) {
				setError(
					`Proposed loan amount ${formatCurrency(
						newLoanAmount,
					)} exceeds the maximum allowed amount of ${formatCurrency(
						maxAmount,
					)}`,
				);
				return !isValid;
			}

			// Unchanged loan amount?
			if (Number(newLoanAmount) === Number(oldLoanAmount)) {
				setError(
					"Proposed loan amount is the exact same as the old loan amount.",
				);
				return !isValid;
			}

			// check if the amount is lower than the allowed min amount
			if (Number(newLoanAmount) < Number(minAmount)) {
				setError(
					`Proposed loan amount ${formatCurrency(
						newLoanAmount,
					)} is lower than the minimum allowed amount of ${formatCurrency(
						minAmount,
					)}`,
				);
				return !isValid;
			}

			// check that the amount is greater than 0
			if (Number(newLoanAmount) <= 0) {
				setError(
					`Proposed loan amount ${formatCurrency(
						newLoanAmount,
					)}  must be greater than zero.`,
				);
				return !isValid;
			}
		}

		return isValid;
	};

	const flowTrigger = async () => {
		const isValid = validate();
		if (!isValid) return;

		const options: {
			[key: string]: any;
		} = {
			adjustedLoanAmount: Number(newLoanAmount),
			comment: updatedComment,
		};

		// Create a new options object without the null values
		for (const key of Object.keys(options)) {
			if (options[key] == null) {
				delete options[key];
			}
		}

		await flow.trigger(options);
	};

	const adjustLoanAmountTask = () => {
		return (
			<>
				<InfoSection>
					{maxAmount && minAmount && (
						<>
							<div style={{ padding: "10px", textAlign: "center" }}>
								<h1 style={{ textAlign: "center" }}>Change Loan Amount</h1>
								<ul style={{ listStyleType: "none", padding: 0 }}>
									<p>
										The loan amount can be adjusted between{" "}
										{formatCurrency(minAmount)} and {formatCurrency(maxAmount)}.
									</p>
									<Icons.Withdrawal />
								</ul>
							</div>

							<div style={{ display: "flex", margin: "auto" }}>
								<Slider
									min={Number(minAmount)}
									max={Number(maxAmount)}
									value={Number(newLoanAmount) ?? Number(oldLoanAmount)}
									onChange={(value) => setNewLoanAmount(Number(value))}
								/>
							</div>

							<div style={{ padding: "10px", textAlign: "center" }}>
								<ul style={{ listStyleType: "none", padding: 0 }}>
									<p>
										{newLoanAmount !== oldLoanAmount && (
											<>
												Loan amount will{" "}
												{oldLoanAmount > newLoanAmount
													? "decrease"
													: "increase"}{" "}
												from {formatCurrency(oldLoanAmount)} to{" "}
												{formatCurrency(newLoanAmount)}
												<p>
													<i>nominal</i> interest rate remains unchanged at{" "}
													{interestRate}%
												</p>
											</>
										)}

										{newLoanAmount === oldLoanAmount && (
											<>
												Current loan amount is {formatCurrency(oldLoanAmount)},
												move the slider to adjust.
											</>
										)}
									</p>
								</ul>
							</div>
						</>
					)}
					{!maxAmount && !minAmount && (
						<div style={{ padding: "10px", textAlign: "center" }}>
							<ul style={{ listStyleType: "none", padding: 0 }}>
								<h1>Change loan amount</h1>
								<p>
									<Icons.AbortedCircle style={{ marginRight: "10px" }} />
									Adjustment is <b>not possible</b>. Missing upsell amount and
									minimum values from Statistica.
								</p>
							</ul>
						</div>
					)}
				</InfoSection>
				<DecisionSectionWrapper>
					{UsertaskApplicantInfo(applicant)}
					<div>
						{UsertaskNumberInput(
							oldLoanAmount,
							null,
							"Current loan amount",
							0,
							Number.POSITIVE_INFINITY,
							true,
						)}

						{UsertaskNumberInput(
							newLoanAmount ?? oldLoanAmount,
							(e) => setNewLoanAmount(e.target.value),
							"New loan amount",
						)}

						{!error ? null : (
							<DecisionSectionDivWrapper
								style={{
									display: "flex",
									backgroundColor: "#e57373",
									justifyContent: "center",
									boxShadow:
										"0px 15px 40px rgb(184 184 184 / 20%), 0px 5px 10px rgb(184 184 184 / 5%)",
									borderRadius: "5px",
									padding: "10px",
								}}
							>
								<span
									style={{
										display: "flex",
										alignItems: "center",
										gridGap: "5px",
										color: "white",
										fontSize: "12px",
									}}
								>
									{error}
								</span>
							</DecisionSectionDivWrapper>
						)}
					</div>
					<div>
						{UsertaskTextField(
							updatedComment,
							(e) => setUpdatedComment(e.target.value),
							"Comment (optional)",
							"",
						)}
					</div>
					{UsertaskButtons(canComplete, flowTrigger)}
				</DecisionSectionWrapper>
			</>
		);
	};

	return CommonUserTask(adjustLoanAmountTask());
};

const InfoSection = styled(InfoSectionWrapper)`
    overflow: auto;
    //Hide scrollbar
    &::-webkit-scrollbar {
        display: none;
    }
`;

export default ChangeLoanAmount;
