import { ApplicantCollection } from "../../../components/Applicant/ApplicantCollection";
import { RadioButtonGroup } from "../../../components/Decision/RadioButtonGroup";
import { FormTextArea } from "../../../components/Input/FormTextArea";
import { CommonUserTaskNew } from "../CommonUserTaskNew";
import type { ComponentConfig } from "../types";
import { useState } from 'react';
import MatchinApplicationsInfo from "./MatchingApplicantsInfo";
import { PaddingContainer, Title, WarningContainerRect } from "./style";
import { WarningIcon } from "../../../components/Icons/WarningIcon";
import ErrorMessages from "../../../components/ErrorMessages/ErrorMessage";
import { CaseDetailsFormConfig } from "./CaseDetails";
import { ApplicantInfo } from "../../../components/Applicant/ApplicantInfo";

const CrossDataCheck = (props: any) => {
  const { flow } = props;
  const isSweden = flow.data.country === "SE";
  const loadedDecision = props?.task?.data.decision || null;
  const loadedComment = props?.task?.data?.comment || "";
  const warningText = isSweden ? "Main applicant, or co-applicant, has 1 or more active application." : "Warning: Applicant data match data in existing applications"
  const hasCoApplicant = !!flow?.data?.agentApiApplication?.coapplicant
  // Cross check flows
  const crossCheckFlows = flow?.data?.crossCheckApplications;
  const flows = crossCheckFlows?.matchingFlows;

  // States
  const [decision, setDecision] = useState<boolean | null>(loadedDecision);
  const [comment, setComment] = useState<string>(loadedComment);
  const [errorMessages, setErrorMessages] = useState<string>("");

  const applicationInfo = {
    email: flow?.data?.agentApiApplication?.applicant?.emailAddress,
    phone: flow?.data?.agentApiApplication?.applicant?.mobileNumber,
    accountNumber: isSweden ? flow?.data?.agentApiApplication.application.bankAccountNumber : flow?.data?.agentApiApplication?.bankAccountNumber,
    addressInformation: {
      streetName: flow?.data?.agentApiApplication?.applicant?.addressInformation?.streetName,
      streetNumber: flow?.data?.agentApiApplication?.applicant?.addressInformation?.streetNumber,
      postalCode: flow?.data?.agentApiApplication?.applicant?.addressInformation?.postalCode,
      city: flow?.data?.agentApiApplication?.applicant?.addressInformation?.city,
      province: flow?.data?.agentApiApplication?.applicant?.addressInformation?.province,
    }
  }

  const applicantInfo = flow?.data?.agentApiApplication?.applicant
  const coApplicantInfo = flow?.data?.agentApiApplication?.coapplicant ? {
    ...flow?.data?.agentApiApplication?.coapplicant,
    isCoApplicant: true,
  } : null;


  const onComplete = () => {

    if (decision === null) {
      setErrorMessages("Please select a decision");
      return;
    }
    const decisionText = decision === false ? "rejected" : "approved";
    const formData = {
      decision: decisionText,
      comment,
    };

    props.complete(
      formData,
      () => { },
      (e: any) => {
        console.log(e);
        setErrorMessages(e?.detail);
      },
    );
  }
  const onSave = () => {
    const formData = {
      decision,
      comment,
    };
    props.save(formData,
      () => console.log("success"),
      (e: any) => {
        console.log(e);
        setErrorMessages(e?.detail);
      },
    )
  }

  const formConfig: ComponentConfig[] = [
    {
      component: (
        <div style={{
          "display": "flex",
          "flexDirection": "column",
          "gap": "1rem"
        }}>
          <ApplicantInfo applicant={applicantInfo} />
          {hasCoApplicant && <ApplicantInfo applicant={coApplicantInfo} />}

        </div>
      ),
      order: 1,
    },
    {
      component: (
        <PaddingContainer>
          <WarningContainerRect>
            <WarningIcon />
            <div>{warningText}</div>
          </WarningContainerRect>
        </PaddingContainer>
      ),
      order: 2,
    },
    {
      component: (
        <PaddingContainer>
          <div style={{
            fontSize: "14px",
            fontWeight: "bold",
            marginBottom: "1rem",
            color: "#1e1e1e"
          }}>Case Details</div>
          <CaseDetailsFormConfig flow={flow} isSweden={isSweden} />
        </PaddingContainer>
      ),
      order: 3,
    },
    {
      component: (
        <RadioButtonGroup
          radioButtonValue={decision}
          onChange={(e: string) =>
            e === "true" ? setDecision(true) : setDecision(false)
          }
          title="Decision"
          options={[
            { value: true, label: "Approve application" },
            { value: false, label: "Reject application" },
          ]}
          id="check-manual-handling"
        />
      ),
      order: 4,
    },
    {
      component: (
        <FormTextArea
          name="Comment"
          onChange={(e: string) => setComment(e)}
          label="Comment"
          defaultValue={comment || ""}
          placeholder="Other applicant information"
        />
      ),
      order: 5,
    },
    {
      component: <ErrorMessages errors={[errorMessages]} />,
      order: 6,
      hide: errorMessages === "",
    },
  ];

  const infoConfig = [
    {
      component: (
        <>
          <Title>Applications with match</Title>
          <MatchinApplicationsInfo flows={flows} applicationInfo={applicationInfo} coApplicantInfo={coApplicantInfo} isSweden={isSweden} />
        </>
      ),
      order: 1,
    },
  ];


  return (
    <CommonUserTaskNew
      handleComplete={onComplete}
      handleSave={onSave}
      infoConfig={infoConfig}
      formConfig={formConfig}
      overrideMasonryColumns={2}
    />
  );
};

export default CrossDataCheck;

