import styled from "styled-components";
import { FlexAlignCenterWrapper } from "../styles";

export const FlexAlignCenter = styled.div`
  display: flex;
  align-items: center;
`;

export const InfoSectionPersonInfoItemWrapper = styled(FlexAlignCenter)`
  justify-content: space-between;
  height: 40px;
  width: 100%;
  border-top: 1px solid #DAD9D8;
`;

export const InfoSectionPersonInfoItem = styled.div`
  padding: 0px 25px;
`;

export const DecisionSectionLeftWrapper = styled(FlexAlignCenterWrapper)`
  padding-left: 25px;
`;

export const LabelBold = styled.label`
  font-weight: 600;
`;

export const ApplicantDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 16px;
  gap: 10px;
`;

export const ApplicantDetailsLabel = styled.div`
  font-weight: 600;
  padding-left: 28px;
  color: #2b2b2b;
`;