import { useState } from "react";
import { CopyIconWrapper, OverviewSVGBlueWrapper } from "./loan/styles";
import { Icons } from "@stacc/flow-ui-components";
import { CopyLinkText } from "./styles";
import { Copy } from "../../components/Icons/Copy";
interface CopyLinkProps {
  title: string
  value: string
}

export const CopyLink = ({ title, value }: CopyLinkProps) => {
  const [hasCopied, setHasCopied] = useState(false);
  function copyToClipboard() {
    setHasCopied(true);
    navigator.clipboard.writeText(value);
    // Reset copied state after 1 second
    setTimeout(() => {
      setHasCopied(false);
    }, 1000);
  }
  return (
    <CopyLinkText onClick={copyToClipboard}>
      {title}
      <OverviewSVGBlueWrapper>
        <CopyIconWrapper>
          {
            hasCopied ? (
              <Icons.Check />
            ) : (
              <Copy />
            )
          }
        </CopyIconWrapper>
      </OverviewSVGBlueWrapper>
    </CopyLinkText>
  )
}