import styled from "styled-components";
import { FormComponentContainer } from "../style";
import type { FormTextProps } from "./types";

/**
 * Renders a form text.
 * @component
 * @param {Object} props - The component props.
 * - label - The text area label.
 * - name - The text area name.
 * - id - The text area id.
 * - onChange - The function to handle the text area change.
 * - onBlur - The function to handle the text area blur.
 * - defaultValue - The text area default value.
 * - disabled - Indicates if the text area is disabled.
 * - required - Indicates if the text area is required.
 * - size - The text area size.
 * @returns {JSX.Element} The rendered component.
 */
export const FormText = (props: FormTextProps) => {
  const hasErrorStyle = props.hasError ? { border: "1px solid #EF4444" } : {};
  return (
    <FormComponentContainer>
      <div style={{ fontWeight: "600" }}>
        {props.label}
      </div>
      <StyledInput
        name={props.name}
        id={props.id}
        onChange={(e: any) => props.onChange(e.target.value)}
        onBlur={props.onBlur}
        defaultValue={props.defaultValue}
        disabled={props.disabled}
        required={props.required}
        style={{ ...hasErrorStyle }}
      />
    </FormComponentContainer>
  );
}

const StyledInput = styled.input`
    border: 1px solid #B8C4E0;
    max-width: 100%;
    padding: 8px;
    border-radius: 5px;
    font-size: 14px;
    color: #1E1E1E;
`;