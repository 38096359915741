import { Icons } from "@stacc/flow-ui-components";
import { OverviewSVGBlueWrapper, ModalIconWrapper } from "./loan/styles";
import { ModalLinkText } from "./styles";

interface CopyLinkProps {
  title: string
  handleOnClick: () => void
}

export const ModalLink = ({ title, handleOnClick }: CopyLinkProps) => {
  return (
    <ModalLinkText onClick={handleOnClick}>
      {title}
      <OverviewSVGBlueWrapper>
        <ModalIconWrapper >
          <Icons.NewTab />
        </ModalIconWrapper>
      </OverviewSVGBlueWrapper>
    </ModalLinkText>
  )
}