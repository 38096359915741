import styled from "styled-components";

export const PdfViewer = styled.div`
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const BoldText = styled.div`
  font-weight: bold;
  font-size: 14px;
`
export const BoldTextMedium = styled.div`
  font-weight: bold;
  font-size: 12px;
`
export const ChecklistWrapper = styled.div`
display: flex;
flex-direction: column;
gap: 20px;
padding-bottom: 20px;
border-bottom: 1px solid #d4dcec;
`

export const InsuranceWrapper = styled.div`
display: flex;
flex-direction: column;
gap: 20px;
margin-top: 20px;
`
export const LightSpan = styled.span`
  font-weight: 400;
  font-size: 12px;
  font-color: #6E7686;
`
export const Wrapper = styled.div`
  display: 'flex',
  margin: '1rem',
`

export const CheckListContainer = styled.div`
width: 40%;
padding: 0 1rem 0 0;
border-right: 1px solid #d4dcec;
`