import { NormalInfoWrapper } from "../../../../tasks/styles";
import QRCode from "react-qr-code";
import { RowsWrapper } from "../../../styles";
import type { CommonData } from "../../../../../types/common";

interface OfferDetailsProps {
  data: CommonData
}
export const SignicatRow = ({ data }: OfferDetailsProps) => {
  const qrData = data?.integrations?.signicat?.auth?.raw?.signicat?.headless?.idpData?.qrData || "";

  return (
    <RowsWrapper className="Signicat details">
      <NormalInfoWrapper $header>Signicat</NormalInfoWrapper>
      <div style={{ padding: "7px 24px" }}>
        <QRCode size={100} value={qrData} />
      </div>
    </RowsWrapper>
  )
}