import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { Colors, FontStyles } from "@stacc/flow-ui-components"

const borderSize = "3px";

export const ItemNavLink = styled(NavLink)`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  text-decoration: none;
  color: inherit;
  border-bottom: 1px solid ${Colors.Grey3};
  ${FontStyles.Small};
  &.active {
    pointer-events: none;
    background-color: ${Colors.SeaLighter};
    border-left: 2px solid ${Colors.SeaDark};
  }
`;

export const Container = styled.div`
  padding: 10px;
  align-items: flex-start;
  border-left: ${borderSize} solid transparent;
`;

export const Heading = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Body = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0px;
  margin: 0px;
`;

export const BodyRight = styled.div`
  text-align: right;
`

export const Title = styled.p`
  ${FontStyles.LargeStrong};
  padding: 0px;
  margin: 0px;
`;

export const CaseHeadingText = styled.p`
  padding: 0px;
  margin: 0px;
`;
