import styled from "styled-components";
export const GapContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const PaddingContainer = styled.div`
  padding: 1rem;
`;

export const InfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #B8C4E0;
  padding: 0.25rem 0;
`;

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
`;

export const InfoText = styled.p`
  margin: 0;
`;