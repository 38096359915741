import type { Flow } from "@flow";
import LoanOverviewEs from "./es";
import LoanOverviewSe from "./se";

export const LoanOverview = (flow: any) => {
  const country = flow?.caseData?.data?.country || ""
  if (country === "SE") {
    return LoanOverviewSe(flow);
  }
  return LoanOverviewEs(flow);
}