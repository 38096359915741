import { useState } from "react";
import { Icons } from "@stacc/flow-ui-components"
import { NormalInfoWrapper } from "../../../tasks/styles";
import { ProcessWrapper, OverviewWrapper, RowsWrapper } from "../../styles";
import type { Statistica } from "@flow";
import type { NormalRows } from "../../types";
import { ApplicantInfoOverview } from "../../../../components/Applicant/ApplicantInfoOverview";
import { OverviewRowCollection } from "../../../../components/Rows/OverviewRowCollection";
import { OverviewHeader } from "../../../../components/Header/OverviewHeader";
import { OverviewIdRowCollection } from "../../../../components/Rows/OverviewIdRowCollection";
import { CopyLink } from "../../copyLink";
import { Modals, ModalTypes } from "../modals";
import { ModalLink } from "../../modalLink";

export const LoanOverviewEs = (flow: any) => {
  //States used to view modals for KYC, transactions & video ID
  const [selectedModal, setSelectedModal] = useState<string>("");

  //Variables used to display data
  const { data, flowId } = flow?.caseData;
  const {
    country,
    agentApiApplication: agentApi,
    offer,
    integrations,
    softOffer,
  } = data || {};
  const { applicant, coapplicant, agentId, appliedAmount, externalApplicationId } =
    agentApi || {};
  const {
    insuranceSelected,
    effectiveInterestRateWithInsurance,
    effectiveInterestRate: effIntRate,
    terms,
    validUntil,
    finalLoanAmount,
  } = offer || {};
  const { validUntil: softOfferValidUntil, loanAmount: softOfferLoanAmount } =
    softOffer || {};
  const { statistica, leabank } = integrations || {};
  const { ownExperience } = leabank || {};

  function handleModalClose() {
    setSelectedModal("");
  }

  const effectiveInterestRate =
    insuranceSelected && effectiveInterestRateWithInsurance
      ? effectiveInterestRateWithInsurance
      : effIntRate;
  const offerExpirationDate = validUntil || softOfferValidUntil;

  // Priority for the loan amount displayed: offer -> softOffer -> agentApiApplication -> hardcoded 0
  const loanAmount =
    finalLoanAmount || softOfferLoanAmount || appliedAmount || 0;

  let rejectionRulesTriggeredByStatistica: any[] = [];
  if (statistica) {
    const typedStatistica: Statistica = statistica;
    rejectionRulesTriggeredByStatistica = Object.entries(typedStatistica)
      .flatMap(([, value]) =>
        value.policyResults
          ? Object.entries(value.policyResults)
            .filter(([, result]) => result === "Red")
            .map(([policy]) => policy)
          : [],
      )
      .flat();
  }


  let formattedExpirationDate;
  let offerExpired = false;
  if (offerExpirationDate) {
    if (new Date(offerExpirationDate) < new Date()) {
      offerExpired = true;
    }
    formattedExpirationDate = Intl.DateTimeFormat("en-GB")
      .format(new Date(offerExpirationDate))
      .replace(/\//g, ".");
  }

  const existingLoanDetailsConfig: NormalRows = [];
  const applicationDetailsRows = [];
  const offerRows = [];
  if (ownExperience?.isTopUp) {
    offerRows.push({
      name: "Total loan amount",
      value: `${new Intl.NumberFormat().format(ownExperience.data?.principalBalance + loanAmount)} €`,
    });
    existingLoanDetailsConfig.push(
      {
        name: "Top Up",
        value: "Yes",
      },
      {
        name: "Current loan amount",
        value: `${new Intl.NumberFormat().format(ownExperience.data?.openInvoices)} €`,
      },
      {
        name: "Current nominal interest rate",
        value: `${ownExperience.data?.currentInt} %`,
      },
      {
        name: "Amount overdue",
        value: `${ownExperience.data?.amtOverDue} €`,
      },
      {
        name: "Days past due (DPD) as of today",
        value: `${ownExperience.data?.dpd}
            ${ownExperience.data?.dpd === 1 ? " day" : " days"}`,
      },
      {
        name: "max DPD last 12 months",
        value: `${ownExperience.data?.maxDPD12M}
            ${ownExperience.data?.maxDPD12M === 1 ? " day" : " days"}`,
      },
      {
        name: "Number of active accounts",
        value: ownExperience.data?.activeAccounts,
      },
    );
    for (const account of ownExperience.data?.activeAccountNOs || []) {
      existingLoanDetailsConfig.push({
        name: "Core Account number",
        value: account,
      });
    }
  }

  const idConfig = [
    { title: "Flow ID", content: (<CopyLink value={flowId} title={"Copy flow ID"} />) },
    { title: "External ID", content: (<CopyLink value={externalApplicationId} title={"Copy external ID"} />) },
  ];

  if (agentId)
    applicationDetailsRows.push({
      name: "Agent",
      value: agentId,
    });

  if (formattedExpirationDate)
    offerRows.push({
      name:
        data?.businessStatus === "loan-disbursed" || offerExpired
          ? "Offer expired"
          : "Offer valid until",
      value:
        data?.businessStatus === "loan-disbursed"
          ? "Disbursed"
          : formattedExpirationDate,
    });

  if (loanAmount)
    offerRows.push({
      name: data?.offer?.finalLoanAmount
        ? "Loan offered"
        : "Applied loan amount",
      value: `${new Intl.NumberFormat().format(loanAmount)}  €`,
    });

  if (effectiveInterestRate)
    offerRows.push({
      name: "Effective interest rate",
      value: `${effectiveInterestRate?.toString().replace(".", ",")}%`,
    });

  if (terms !== undefined)
    offerRows.push({
      name: "Terms",
      value: terms,
    });

  offerRows.push({
    name: "Offer given",
    value: data?.offer?.finalLoanAmount ? (
      <Icons.SuccessCircle />
    ) : flow?.caseData?.status === "active" ? (
      <Icons.ActiveCircle />
    ) : (
      <Icons.AbortedCircle />
    ),
  });

  if (
    rejectionRulesTriggeredByStatistica &&
    rejectionRulesTriggeredByStatistica.length > 0
  ) {
    const rules = rejectionRulesTriggeredByStatistica.map(
      (rule) => `• ${rule}`,
    );
    applicationDetailsRows.push({
      name: "Rejection reasons from Statistica",
      value: (
        <>
          {rules.map((el, index) => {
            return (
              <div
                key={index}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                {el}
              </div>
            );
          })}
        </>
      ),
    });
  }

  if (applicant?.psD2Data?.accounts && applicant?.psD2Data?.accounts.length > 0)
    applicationDetailsRows.push({
      name: "Transactions",
      value: (<ModalLink title="View Transactions" handleOnClick={() => setSelectedModal(ModalTypes.MANUAL_PAYOUT_APPROVAL)} />),
    });

  if (data?.integrations?.cm1)
    applicationDetailsRows.push({
      name: "KYC",
      value: (<ModalLink title="View KYC" handleOnClick={() => setSelectedModal(ModalTypes.KYC)} />),
    });

  if (data?.integrations?.electronicIdVideoId)
    applicationDetailsRows.push({
      name: "Video ID",
      value: (<ModalLink title="View Video ID" handleOnClick={() => setSelectedModal(ModalTypes.VIDEO_ID)} />),
    });

  applicationDetailsRows.push({
    name: "Purpose",
    value: data?.agentApiApplication?.purpose,
  });

  return (
    <OverviewWrapper>
      <OverviewHeader country={country} label="Loan application" />
      <ProcessWrapper>
        <ApplicantInfoOverview applicant={applicant} coapplicant={coapplicant} />
        <RowsWrapper>
          <NormalInfoWrapper $header>Application Details</NormalInfoWrapper>
          {OverviewIdRowCollection({ config: idConfig })}
          {OverviewRowCollection({ rows: applicationDetailsRows })}
        </RowsWrapper>
        <RowsWrapper>
          <NormalInfoWrapper $header>Offer Details</NormalInfoWrapper>
          {OverviewRowCollection({ rows: offerRows })}
        </RowsWrapper>
        {ownExperience?.isTopUp ? (
          <RowsWrapper>
            <NormalInfoWrapper $header>Existing Loan Details</NormalInfoWrapper>
            {OverviewRowCollection({ rows: existingLoanDetailsConfig })}
          </RowsWrapper>
        ) : null}
      </ProcessWrapper>
        <Modals flow={flow} modalToShow={selectedModal} handleModalClose={handleModalClose} />
    </OverviewWrapper>
  );
};

export default LoanOverviewEs;
