import styled from "styled-components";
import { Collapsible } from "@stacc/flow-ui-components"
import { Colors } from "@stacc/flow-ui-components"

export const DownloadFileWrapper = styled.div`
  button {
    &:hover {
      background-color: ${Colors.Grey3};
    }
  }
`;

export const FileHeader = styled.div`
  padding: 30px 30px;
  div {
    font-weight: 500;
    font-size: 16px;
    color: #030303;
    text-transform: capitalize;
  }
  &:hover {
    background-color: ${Colors.Grey4};
  }
`;

export const FileName = styled.div`
font-size: 100px;
`;

export const FileGroup = styled.div`
  border: 1px solid ${Colors.Grey3};
  border-radius: 4px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  margin: 15px;
`;

export const StyledCollapsible = styled(Collapsible)`
  background-color: red;
`;

export const File = styled.div`
  margin: 5px 0;
  div {
    font-weight: 400;
  }
  display: grid;
  grid-template-columns: 50% 40% 35%;
  flex-direction: row;
  gap: 20px;
  justify-content: space-between;
  align-items: center;
`;

export const DownloadButtonArea = styled.div`
  display: grid;
  grid-template-columns: 50%;
  margin-top: 25px;
`;

export const Container = styled.div`
  border-top: 1px solid ${Colors.Grey3};
`;

export const TimeStamp = styled.div``;