import type { CommonData } from "../../../../../types/common";
import { NormalInfoWrapper } from "../../../../tasks/styles";

interface OfferDetailsProps {
  data: CommonData
}
export const ApplicantRow = ({ data }: OfferDetailsProps) => {
  const SE_Scorecard = data?.integrations?.statistica?.SE_Scorecard
  const applicant = data?.agentApiApplication?.applicant;

  const addressInformation = data?.integrations?.uc?.addressInformation;
  const address = addressInformation
    ? `${addressInformation.streetName}, ${addressInformation.postcode}, ${addressInformation.municipality}`
    : "Address not available";

  const applicantDetails = [
    { label: "Age", value: SE_Scorecard?.variables?.Applicant_Age },
    { label: "Address", value: address },
    { label: "Living conditions", value: applicant.dwelling.type },
    { label: "Marital status", value: applicant.maritalStatus },
    { label: "Children (under 18)", value: applicant.numberOfChildrenBelow18 },
    { label: "Employment status", value: applicant.employment.type },
  ]
  return (
    <>
      <NormalInfoWrapper $header>Applicant Details</NormalInfoWrapper>
      {applicantDetails.map((detail, index) => (
        <NormalInfoWrapper key={`offer - ${detail.label}`} $isLast={index === applicantDetails.length - 1}>
          <div>{detail.label}</div>
          <div>{detail.value}</div>
        </NormalInfoWrapper>
      ))}
    </>
  )
}