import type React from "react";
import { Fragment, useState, memo } from "react";
import {
  DynamicTableContainer,
  HeaderGrid,
  RowGrid,
  StyledChevron,
} from "./style";
import type {
  DynamicTableFileViewProps,
  DynamicTableProps,
  HeaderContentProps,
  PdfViewerProps,
  RowContentProps,
} from "./types";
import { FilePreview } from "../Files/PreviewFile";
import DeleteIcon from "../Icons/Delete";
import { LoadingSpinner } from "../Spinner/LoadingSpinner";

const DynamicTable = ({
  data,
  columns,
  mainHeader,
  flowId,
  number,
  handleRowClick,
  handleDelete,
  deleteItem,
  user,
}: DynamicTableProps) => {
  const [openRowIndex, setOpenRowIndex] = useState<number | null>(null);
  const nrOfColumns = columns.length;

  return (
    <DynamicTableContainer>
      <table>
        <thead>
          <tr>
            <th colSpan={nrOfColumns}>{mainHeader}</th>
            <th colSpan={1} style={{ textAlign: "right" }}>
              {number}
            </th>
          </tr>
        </thead>

        <tbody>
          <HeaderContent columns={columns} nrOfColumns={nrOfColumns} />
          {data.map((item: DynamicTableFileViewProps, index: number) => (
            <Fragment
              key={`table row: ${
                // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                index
              }`}
            >
              <RowContent
                columns={columns}
                file={item}
                handleClick={() => handleRowClick(item)}
                handleDelete={() => handleDelete(item)}
                isOpen={openRowIndex === index}
                nrOfColumns={nrOfColumns}
                deleteItem={deleteItem}
                user={user}
              />
            </Fragment>
          ))}
        </tbody>

        <tfoot>
          <tr>
            <td
              colSpan={nrOfColumns}
              style={{ textAlign: "right", padding: "5px" }}
            />
          </tr>
        </tfoot>
      </table>
    </DynamicTableContainer>
  );
};

export default DynamicTable;

const HeaderContent = ({ columns, nrOfColumns }: HeaderContentProps) => {
  return (
    <tr>
      <td colSpan={nrOfColumns}>
        <HeaderGrid nrOfColumns={nrOfColumns}>
          {columns.map((column) => (
            <div style={{ padding: "0px 16px" }} key={column.key}>
              {column.title}
            </div>
          ))}
        </HeaderGrid>
      </td>
    </tr>
  );
};

const RowContent = memo(
  ({
    columns,
    file,
    handleClick,
    handleDelete,
    isOpen,
    nrOfColumns,
    deleteItem,
    user,
  }: RowContentProps) => {
    return (
      <tr>
        <td colSpan={nrOfColumns}>
          <RowGrid nrOfColumns={nrOfColumns}>
            {columns.map((column) => (
              <div
                style={{ padding: "0px 16px" }}
                key={column.key}
                onClick={handleClick}
              >
                {column.key === "attachment" ? (
                  <StyledChevron $open={isOpen} />
                ) : (
                  file[column.key]
                )}
              </div>
            ))}
          </RowGrid>
        </td>
        {user?.profile?.role === "admin-es" ? (
          <td style={{ display: "flex", justifyContent: "flex-start" }}>
            {deleteItem?.isDeleting &&
            deleteItem?.item?.filename === file?.filename ? (
              <LoadingSpinner width={24} height={24} />
            ) : (
              <DeleteIcon onClick={handleDelete} />
            )}
          </td>
        ) : null}
      </tr>
    );
  },
);

export const PdfViewer = ({ item, shouldShow, flowId }: PdfViewerProps) => {
  if (!shouldShow) return null;

  return (
    <div>
      <FilePreview
        attachmentKey={item?.attachmentKey ?? item?.name}
        flowId={flowId}
        fileType={
          item?.filetype ?? item?.filename.split(".").pop()?.toLowerCase()
        }
      />
    </div>
  );
};
