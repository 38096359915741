import Accordion from "../../../components/Accordion/Accordion";
import { NewTabIcon } from "../../../components/Icons/NewTabIcon";
import { InfoContainer, LinkContainer, LinkWrapper, StyledLink } from "./style";
import { useLocation } from 'react-router-dom';
import type { MatchinApplicationsInfoProps } from "./types";
import { CaseDetailsEs, CaseDetailsSe } from "./CaseDetails";

const MatchinApplicationsInfo = ({ flows, applicationInfo, isSweden, coApplicantInfo }: MatchinApplicationsInfoProps) => {
  const linkText = "Open application in new tab";
  const location = useLocation();
  const path = location.pathname.split('/').slice(0, -3).join('/');

  const onLinkClick = (flowId: string) => {
    window.open(`${path}/${flowId}`, '_blank');
  }

  return (
    <InfoContainer>
      {flows?.map((flow: any, index: number) => {
        const referenceId = flow.referenceId;
        const isFirst = index === 0;
        return (
          <Accordion title={referenceId} key={`${flow.flowId} + ${index}`} startsExpanded={isFirst}>
            {isSweden ? (
              <CaseDetailsSe flow={flow} applicationInfo={applicationInfo} coApplicantInfo={coApplicantInfo} showLoan showMatch />)
              : (<CaseDetailsEs flow={flow} applicationInfo={applicationInfo} showMatch />)
            }
            <LinkContainer>
              <LinkWrapper>
                <StyledLink onClick={() => onLinkClick(flow.flowId)}>{linkText}</StyledLink>
                <NewTabIcon />
              </LinkWrapper>
            </LinkContainer>
          </Accordion>
        )
      })}
    </InfoContainer>
  )
}

export default MatchinApplicationsInfo;