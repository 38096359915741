import { checklistOptions, insuranceChecklistOptions } from "./options";

export function validateChecklist(checklist: any) {
  if (checklist.length === 0) {
    return "Please go through the checklist.";
  }
  const missingChecklistItems = checklistOptions.filter(
    (option) => !checklist.includes(option.value),
  );
  if (missingChecklistItems.length > 0) {
    return `Please select the following checklist items: ${missingChecklistItems.map((item) => item.label).join(", ")}`;
  }
  const missingInsuranceItems = insuranceChecklistOptions.filter(
    (option) => !checklist.includes(option.value),
  );
  if (missingInsuranceItems.length > 0) {
    return `Please select the following insurance checklist items: ${missingInsuranceItems.map((item) => item.label).join(", ")}`;
  }
  return null;
}

export function validateDecision(decision: any) {
  if (decision === null) {
    return "Please select a decision.";
  }
  return null;
}

export function validateInsurance(insurance: any) {
  if (insurance === null) {
    return "Please select an insurance option.";
  }
  return "";
}
