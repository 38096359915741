import styled from "styled-components";
import { getColorForEnvironment } from "../tasks/styles";

export const ProcessWrapper = styled.div`
    display: flex;
    flex-direction: column;
    background-color: #FFFFFF;
    border-radius: 8px;
    padding: 16px;
    gap: 16px;
`;

export const OverviewWrapper = styled.div`
    display: flex;
    flex-direction: column;
    border: 1px solid #B8C4E0;
    border-radius: 8px;
    `;

export const ProcessHeader = styled.div`
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    padding: 16px;
    gap: 8px;
    background-color: ${getColorForEnvironment()};
    border-radius: 8px 8px 0px 0px;
    border-bottom: 1px solid #B8C4E0;
`;

export const RowsWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-flow: row;
    max-width: -webkit-fill-available;
    font-size: 14px;
`;

export const ProcessIDWrapper = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
`;

export const CopyLinkText = styled.div`
    display: flex;
    color: #1B4DF5;
    cursor: pointer;
    align-items: center;
    justify-content: center;
`;

export const ModalLinkText = styled.div`
    display: flex;
    color: #1B4DF5;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    text-decoration: underline;
`;