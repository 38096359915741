import styled from "styled-components";

export const OverviewSVGBlueWrapper = styled.div`
    position: relative;
    rect {
        fill: #2969FF;
    }
`;

export const OverviewSVGGreenWrapper = styled.div`@
    position: relative;
    rect {
        fill: green;
    }
`;

export const OverviewLinks = styled.a`
    text-decoration: underline;
    color: #2F80ED;
    font-weight: 400;
    font-size: 14px;
    cursor: pointer;
`;

export const CopyIconWrapper = styled.div`
    position: absolute;
    transform: translate(25%, -40%);
`;

export const ModalIconWrapper = styled.div`
    position: absolute;
    transform: translateY(-50%);
`;
export const OfferGivenWrapper = styled.div`
    position: absolute;
    transform: translate(-100%, -50%);
`;