import axios from "axios";

export const getAttachmentMeta = async (
  flowId: string,
  attachmentKey: string,
) => {
  const url = `/api/flow/flows/${flowId}/attachments/${attachmentKey}`;
  return axios.get(url);
};

export const getAttachmentFile = async (
  flowId: string,
  attachmentKey: string,
) => {
  const url = `/api/flow/flows/${flowId}/attachments/${attachmentKey}/download`;
  return axios.get(url, { responseType: "arraybuffer" });
};
