export const defaultNoDataText = "No data found";
export interface ApplicationInfo {
  email: string;
  phone: string;
  accountNumber: string;
  addressInformation: {
    streetName: string;
    streetNumber: string;
    postalCode: string;
    city: string;
    province: string;
  };
}

export interface CoApplicantInformation {
  firstName: string;
  lastName: string;
  nationalId: string;
  birthDate: string;
  gender: string;
  emailAddress: string;
  mobileNumber: string;
}
export interface MatchinApplicationsInfoProps {
  flows: any;
  applicationInfo: ApplicationInfo;
  coApplicantInfo?: CoApplicantInformation;
  isSweden: boolean;
}

export interface CaseDetailsProps {
  flow: any;
  showLoan?: boolean;
  showMatch?: boolean;
  applicationInfo?: ApplicationInfo;
  isSweden?: boolean;
}

export interface CaseDetailsPropsSe {
  flow: any;
  showLoan?: boolean;
  showMatch?: boolean;
  applicationInfo: ApplicationInfo;
  coApplicantInfo?: CoApplicantInformation;
  isSweden?: boolean;
}

export interface InfoRowProps {
  title: string;
  value: string;
  hasMatch: boolean;
  hasPadding?: boolean;
  hasBorderBottom?: boolean;
  isAddress?: boolean;
}

export interface ApplicantInfoProps {
  flow: any;
  info: {
    email: any[];
    phone: any[];
  };
  isMainApplicant: boolean;
  hasCoApplicant: boolean;
}
