import { RadioButtons } from "@stacc/flow-ui-components"
import { FormComponentContainer } from "./styles";

type RadioButtonGroupProps = {
  radioButtonValue: boolean | string | number | null;
  onChange: (value: any) => void;
  title: string;
  options: { value: boolean | string | number; label: string }[];
  id: string;
  disabled?: boolean;
};

export const RadioButtonGroup = (props: RadioButtonGroupProps) => {
  return (
    <FormComponentContainer>
      <div style={{ fontWeight: "600", maxWidth: "300px" }}>{props.title}</div>
      <RadioButtons
        options={props.options}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          props.onChange(e.target.value)
        }
        name={props.id}
        checkedValue={props.radioButtonValue ?? ""}
        disabled={props?.disabled}
      />
    </FormComponentContainer>
  );
};
