import { Checkboxes } from "@stacc/flow-ui-components"
import { BoldText, BoldTextMedium, ChecklistWrapper, InsuranceWrapper, LightSpan } from "./style"
import type { Dispatch, SetStateAction } from "react"
import { additionalChecklistOptions, checklistOptions, insuranceChecklistOptions } from "./options"

interface ChecklistProps {
  checklistValues: string[];
  setChecklistValues: Dispatch<SetStateAction<string[]>>;
}

export const Checklist = ({ checklistValues, setChecklistValues }: ChecklistProps) => {

  function handleChecklistChange(value: any) {
    if (checklistValues.includes(value)) {
      setChecklistValues(checklistValues.filter((item: any) => item !== value))
      return
    }
    setChecklistValues([...checklistValues, value])
  }
  return (
    <div>
      <ChecklistWrapper>
        <BoldText>Checklist </BoldText>
        <Checkboxes
          options={checklistOptions}
          name="Checklista"
          onChange={(e: any) => { handleChecklistChange(e.target.value) }}
          checkedValues={checklistValues} />
        <BoldTextMedium>Evt också: <LightSpan>(Optional)</LightSpan> </BoldTextMedium>
        <Checkboxes
          options={additionalChecklistOptions}
          name="Ytterligare frågor"
          onChange={(e: any) => { handleChecklistChange(e.target.value) }}
          checkedValues={checklistValues}
        />
      </ChecklistWrapper>

      <InsuranceWrapper>
        <BoldTextMedium>Forsåkring </BoldTextMedium>
        <Checkboxes
          options={insuranceChecklistOptions}
          name="Försäkringsfrågor"
          onChange={(e: any) => { handleChecklistChange(e.target.value) }}
          checkedValues={checklistValues}
        />
      </InsuranceWrapper>
    </div>
  )
}