import { TextArea } from "@stacc/flow-ui-components"
import { FormComponentContainer } from "../Decision/styles";
import type { FormTextAreaProps } from "./types";

/**
 * Renders a form text area.
 * @component
 * @param {Object} props - The component props.
 * - label - The text area label.
 * - name - The text area name.
 * - id - The text area id.
 * - onChange - The function to handle the text area change.
 * - onBlur - The function to handle the text area blur.
 * - defaultValue - The text area default value.
 * - disabled - Indicates if the text area is disabled.
 * - required - Indicates if the text area is required.
 * - rows - The text area rows.
 * - cols - The text area cols.
 * - size - The text area size.
 * @returns {JSX.Element} The rendered component.
 */
export const FormTextArea = (props: FormTextAreaProps) => {
  return (
    <FormComponentContainer>
      <div style={{ fontWeight: "600" }}>{props.label}</div>
      <TextArea
        style={{ width: "100%", maxWidth: "100%", resize: "none", border: "1px solid #B8C4E0" }}
        name={props.name}
        id={props.id}
        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
          const event = e as unknown as React.ChangeEvent<HTMLInputElement>;
          props.onChange(event.target.value);
        }}
        onBlur={props.onBlur}
        defaultValue={props.defaultValue}
        disabled={props.disabled}
        required={props.required}
        rows={props.rows}
        cols={props.cols}
        size={props.size}
        placeholder={props.placeholder ?? ""}
      />
    </FormComponentContainer>
  );
};
