import { WarningIcon } from "../Icons/WarningIcon"
import type { InfoRowProps } from "../../views/tasks/cross-data-check/types"
import { BorderBottom, InfoText, InfoWrapper, WarningContainer } from "./style"

export const InfoRow = ({ title, value, hasMatch, hasPadding = true, hasBorderBottom = true }: InfoRowProps) => {
  return (
    <BorderBottom $hasPadding={hasPadding} $hasBorderBottom={hasBorderBottom} >
      <InfoWrapper>
        <InfoText $hasTextRight={false}>{title}</InfoText>
        {hasMatch && <WarningContainer>
          <WarningIcon />
          <InfoText $hasTextRight={false} style={{
            color: "#9E3F00"
          }}>Match</InfoText>
        </WarningContainer>
        }
      </InfoWrapper>
      <InfoText $hasTextRight>{value}</InfoText>
    </BorderBottom>
  )
}