import styled from "styled-components";

export const Container = styled.div`
  border: 1px solid #d4dcec;
  border-radius: 8px;
  overflow: hidden;
  background-color: #ffffff;
  min-width: 650px;
`;

export const Title = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.7rem 1rem;
    font-size: 14px;
    font-weight: 600;
    line-height: 19.6px;
    width: 100%;
    border-radius: 0.5rem;
  `;

export const ContentWrapper = styled.div`
    transition: max-height 0.25s ease-in-out;
    overflow: hidden;
  `;

export const Content = styled.div`
    padding: 0 0 1rem;
    color: rgba(0, 0, 0, 0.75);
  `;

export const TitleContainer = styled.div`
    display: flex;
    justify-content: space-between;
    background-color: #f8f9fc;
    color: #1e1e1e;
    border-bottom: 1px solid #d4dcec;
  `;