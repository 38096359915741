import type { CommonData } from "../../../../../types/common";
import { NormalInfoWrapper } from "../../../../tasks/styles";
import { RowsWrapper } from "../../../styles";

interface OfferDetailsProps {
  data: CommonData
}
export const FinancialRow = ({ data }: OfferDetailsProps) => {
  const SE_External = data?.integrations?.statistica?.SE_External
  const SE_Scorecard = data?.integrations?.statistica?.SE_Scorecard
  const totUnsecuredDebtBal = SE_External?.variables?.totUnsecuredDebtBal; // TODO: Check if this is the correct variable
  const debtRatio = SE_External?.variables?.debtRatio; //TODO: Check if this is the correct variable
  const DebtToBeRedeemed = SE_External?.variables?.DebtToBeRedeemed; //TODO: Check if this is the correct variable
  const ProbabilityOfDefaul = SE_Scorecard?.variales?.ProbabilityOfDefaul //TODO: Check if this is the correct variable
  const OwnsDwelling = SE_Scorecard?.variales?.OwnsDwelling //TODO: Check if this is the correct variable

  //TODO: need "inntekt siste år" and "gjeld siste år" from SE_External

  const financialDetails = [
    { label: "Inntekt siste år (snitt siste 3 år)", value: "??" },
    { label: "Gjeld siste år (snitt siste 3 år)", value: "??" },
    { label: "Total gjeldsgrad", value: debtRatio },
    { label: "Usikret gjeldsgrad", value: totUnsecuredDebtBal },
    { label: "Sum innfrielse", value: DebtToBeRedeemed },
    { label: "PD", value: ProbabilityOfDefaul },
    { label: "OD", value: OwnsDwelling },
  ];
  return (
    <RowsWrapper className="Financial information">
      <NormalInfoWrapper $header>Financial Details</NormalInfoWrapper>
      {financialDetails.map((detail, index) => (
        <NormalInfoWrapper key={`offer - ${detail.label}`} $isLast={index === financialDetails.length - 1}>
          <div>{detail.label}</div>
          <div>{detail.value}</div>
        </NormalInfoWrapper>
      ))}
    </RowsWrapper>
  )
}