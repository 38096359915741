/* eslint-disable */
import React from 'react';
import { createRoot } from 'react-dom/client';
import * as serviceWorker from "./serviceWorker"
import App from "./App";
import "@stacc/flow-ui-style/dist/reset.css";
import "@stacc/flow-ui-style/dist/global.css";

// Get the root element from the DOM
const rootElement = document.getElementById("root");

// Create a root and render the App component
if (rootElement) {
  const root = createRoot(rootElement);
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
}

serviceWorker.unregister()
