import { Checklist } from "./checklist";
import { BoldText, CheckListContainer, PdfViewer } from "./style";
import PdfPage from "../../../components/documentsViewer/PdfPage";
import InfoConfigContainer from "../../../components/InfoConfigContainer/InfoConfigContainer";
import type { Dispatch, SetStateAction } from "react";

interface InfoConfigProps {
  checklistValues: string[];
  setChecklistValues: Dispatch<SetStateAction<string[]>>;
}
export const infoConfig = ({ checklistValues, setChecklistValues }: InfoConfigProps) => {

  return [
    {
      component: (
        <>
          <InfoConfigContainer title="Final Disbursement Check Script" >
            <div style={{
              display: 'flex',
              margin: '1rem',
            }}>
              <CheckListContainer>
                <Checklist checklistValues={checklistValues} setChecklistValues={setChecklistValues} />
              </CheckListContainer>
              <div style={{
                paddingLeft: '1rem',
                display: 'flex',
                flexDirection: 'column',
              }}>

                <BoldText>Script</BoldText>
                <PdfViewer>
                  <PdfPage pdfPath="/Disbursement.check.script.pdf" scale={1} width={500} />
                </PdfViewer>
              </div>
            </div>

          </InfoConfigContainer>
        </>
      ),
      order: 1,
    },
  ]

};