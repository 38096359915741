
export const checklistOptions = [
  { label: "Telefonnummer", value: "phone" },
  { label: "E-postadress", value: "email" },
  { label: "Din bostadsadress", value: "address" },
  { label: "Din tidigare bostadsadress?", value: "previous-address" },
  { label: "Ditt personnummer", value: "personal-number" },
  { label: "Ditt kontonummer, hela clearing och kontonummer", value: "account-number" },
  { label: "Syfte kontrollerad", value: "purpose-checked" },
]

export const additionalChecklistOptions = [
  { label: "Vad är ditt lägenhetsnummer?", value: "apartment-number" },
  { label: "Vilken våning bor du på?", value: "floor" },
  { label: "Äger du en bil?", value: "car" },
]

export const insuranceChecklistOptions = [
  { label: "Jobbar du minst 50% och har en permanent anställning?", value: "permanent-employment" },
  { label: "Känner du till någon möjlig uppsägning, arbetsoförmåga eller sjukfrånvaro?", value: "termination" },
  { label: "Är du ansluten till a-kassan och f-kassan?", value: "unemployment-insurance" },
]