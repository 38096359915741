import type { ReactNode } from "react";
import { Container, TitleContainer, Title, ContentWrapper, Content } from "./style";

export interface InfoConfigContainerPros {
  title: string;
  children?: ReactNode;
}

const InfoConfigContainer = ({ title, children }: InfoConfigContainerPros): JSX.Element => {

  return (
    <Container>
      <TitleContainer className="title-container">
        <Title>
          {title}
        </Title>
      </TitleContainer>
      <ContentWrapper >
        <Content>{children}</Content>
      </ContentWrapper>
    </Container>
  );
};


export default InfoConfigContainer;

