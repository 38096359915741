// Button.tsx
import type React from "react";
import { TransparentButton } from "@stacc/flow-ui-components"
import type { TabButtonProps } from "./types";
import { StyledTabButton } from "./styles";

export const TabButton = (props: TabButtonProps) => {
  return (
    <StyledTabButton
      as={TransparentButton}
      disabled={props.disabled}
      selected={props.selected}
      onClick={props.onClick}
    >
      {props.label}
    </StyledTabButton>
  );
};
