import { Icons } from "@stacc/flow-ui-components";
import { NormalInfoWrapper } from "../../../../tasks/styles";
import { OfferGivenWrapper } from "../../styles";
import { RowsWrapper } from "../../../styles";
import type { CommonData } from "../../../../../types/common";

interface OfferDetailsProps {
  data: CommonData
  status: string
}
export const OfferRow = ({ data, status }: OfferDetailsProps) => {
  const finalLoanAmount = data?.offer?.finalLoanAmount || 0;
  const businessStatus = data?.businessStatus;

  // Offer valid until
  const { offer } = data || {};
  const { validUntil } = offer || {};
  const offerExpirationDate = validUntil;
  const offerExpired = offerExpirationDate ? new Date(offerExpirationDate) < new Date() : false;
  let formattedExpirationDate = offerExpired ? "Offer expired" : offerExpirationDate;
  if (offerExpirationDate) {
    formattedExpirationDate = Intl.DateTimeFormat("en-GB")
      .format(new Date(offerExpirationDate))
      .replace(/\//g, ".");
  }
  const offerValidUntilValue = businessStatus === "loan-disbursed" || offerExpired ? "Disbursed" : formattedExpirationDate;
  const offerValidUntilText = businessStatus === "loan-disbursed" || offerExpired ? "Offer expired" : "Offer valid until";

  // Loan amount
  const loanAmount = finalLoanAmount || 0;

  // Effective interest rate
  const effectiveInterestRate = data?.offer?.effectiveInterestRate || "N/A";

  const terms = data?.offer?.terms;

  const offerGivenIcon = finalLoanAmount ? (
    <Icons.SuccessCircle />
  ) : status === "active" ? (
    <Icons.ActiveCircle />
  ) : (
    <Icons.AbortedCircle />
  );

  const offerDetails = [
    { label: offerValidUntilText, value: offerValidUntilValue },
    { label: "Loan Amount", value: `${new Intl.NumberFormat().format(finalLoanAmount)} kr`,},
    { label: "Effective Interest Rate", value: `${effectiveInterestRate}%` },
    { label: "Terms", value: terms },
    {
      label: "Offer given", value: <div style={{
        position: "relative",
      }}><OfferGivenWrapper>{offerGivenIcon}</OfferGivenWrapper></div>
    }
  ]
  return (
    <RowsWrapper className="Offer information">
      <NormalInfoWrapper $header>Offer Details</NormalInfoWrapper>
      {offerDetails.map((detail, index) => (
        <NormalInfoWrapper key={`Offer row - ${detail.label}`} $isLast={index === offerDetails.length - 1}>
          <div>{detail.label}</div>
          <div>{detail.value}</div>
        </NormalInfoWrapper>
      ))}
    </RowsWrapper>
  )
}