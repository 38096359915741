import React from "react";
import { intervalToDuration } from "date-fns";
import { Icons } from "@stacc/flow-ui-components"
import { NormalInfoWrapper, SVGArrowWrapper } from "../../tasks/styles";
import {
  ProcessWrapper,
  ProcessIDWrapper,
  ProcessHeader,
  OverviewWrapper,
} from "../styles";
import type { NormalRows } from "../types";
import type { DeletionCriteria } from "./types";

export const CleanupOverview = (flow: any) => {
  //State used for opening and closing the ID section
  const [idOpen, setIdOpen] = React.useState(false);

  //Variables used to display data
  const data = flow?.caseData;

  // Deletion Config
  const queriesUsed: DeletionCriteria = data?.data?.deletionCriteria;
  const retentionPeriod = `${data?.data?.maxAgeAllowedForDeniedOrCanceledApplications} days`;
  const deletionThreshold = `${data?.data?.maxNumFlowsToDeletePerProcess} flows`;
  const totalCount = `${data?.data?.totalCount} flows`;

  // Deletion Results and Config
  const cleanupResult = data?.data?.cleanupResult;
  const deletedFlows = cleanupResult?.numDeletedFlows;
  const archivedFlows = cleanupResult?.numArchivedFlows;
  const errors = cleanupResult?.numErrors;
  const numBatches = data?.data?.numberOfBatches;
  const batchSize = `${data?.data?.batchSize} flows`;

  // Specific to manual cleanup
  const manualCleanupResult = cleanupResult?.cleanedUpFlow;
  const manualCleanupFlowIdNotFound = data?.data?.result?.flowNotFoundMessage;

  // Time data
  const createdAt = data?.createdAt;
  const completedAt = data?.completedAt ?? new Date().toISOString();
  const duration = intervalToDuration({
    start: new Date(createdAt),
    end: new Date(completedAt),
  });

  //Renders one row in the overview section.
  const renderNormalInfoRow = (label: string, value: any) => {
    return (
      <NormalInfoWrapper key={label}>
        <div>{label}</div>
        <div>{value}</div>
      </NormalInfoWrapper>
    );
  };

  // Objects in this array wil be rendered as simple rows in the overview.
  const cleanUpConfig = [
    {
      name: "Retention period",
      value: retentionPeriod,
      processes: ["cleanup"],
    },
    {
      name: "Deletion limit",
      value: deletionThreshold,
      processes: ["cleanup"],
    },
    {
      name: "Number of flows found to delete",
      value: totalCount,
      processes: ["cleanup"],
    },
    {
      name: "Duration",
      value: `${duration.minutes && duration.minutes < 10 ? "0" : ""}${duration.minutes}:${duration.seconds && duration.seconds < 10 ? "0" : ""}${duration.seconds}`,
      processes: ["cleanup", "manual-cleanup"],
    },
    {
      name: "Applications deleted",
      value: deletedFlows,
      processes: ["cleanup"],
    },
    {
      name: "Applications archived",
      value: archivedFlows,
      processes: ["cleanup"],
    },
    {
      name: "Manual cleanup finished",
      value: manualCleanupResult,
      processes: ["manual-cleanup"],
    },
    {
      name: "Errors",
      value: errors ?? 0,
      processes: ["cleanup"],
    },
    {
      name: "Maximum batch size",
      value: batchSize,
      processes: ["cleanup"],
    },
    {
      name: "Number of batches",
      value: numBatches,
      processes: ["cleanup"],
    },
  ];

  if (manualCleanupFlowIdNotFound) {
    cleanUpConfig.push({
      name: `The input flowID "${data?.data?.flowId}" does not exist. Nothing to delete`,
      value: "",
      processes: ["manual-cleanup"],
    });
  }

  //Renders a array of rows
  const renderRows = (normalRows: NormalRows) => {
    return normalRows.map((row) => {
      if (row.processes && !row.processes.includes(flow.flowDefinitionId))
        return null;

      return renderNormalInfoRow(row.name, row.value);
    });
  };

  const renderQueriesUsed = () => {
    return (
      <>
        <NormalInfoWrapper
          onClick={() => setIdOpen(!idOpen)}
          style={{ cursor: "pointer" }}
        >
          <div> Queries Executed</div>
          <SVGArrowWrapper>
            {!idOpen ? <Icons.ChevronDown /> : <Icons.ChevronUp />}
          </SVGArrowWrapper>
        </NormalInfoWrapper>

        {idOpen
          ? // render a component for each element in the array deletionCriteria
          queriesUsed.map((criteria) => {
            return (
              <NormalInfoWrapper key={criteria}>
                <ProcessIDWrapper>{criteria}</ProcessIDWrapper>
              </NormalInfoWrapper>
            );
          })
          : null}
      </>
    );
  };

  // Actual returned component
  return (
    <OverviewWrapper>
      <ProcessHeader>Cleanup Process</ProcessHeader>
      <ProcessWrapper>
        {renderRows(cleanUpConfig)}
        {flow.flowDefinitionId === "cleanup" ? renderQueriesUsed() : null}
      </ProcessWrapper>
    </OverviewWrapper>
  );
};

export default CleanupOverview;
