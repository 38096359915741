import styled from "styled-components";
import { getColorForEnvironment, InfoSectionWrapper } from "../styles";
import { Colors } from "@stacc/flow-ui-components"

export const CustomInfoSectionWrapper = styled(InfoSectionWrapper)`
  display: flex;
  flex-direction: column;
  overflow: auto;
`;

export const RawData = styled.div`
  font-family: sans-serif;
  background-color: ${Colors.Grey1};
  color: ${Colors.Snow};
  padding: 15px;
  max-width: 50vw;
  overflow: auto;
  max-height: 100%;
`;

export const FlexJustifyCenter = styled.div`
  display: flex;
  justify-content: center;
`;

export const FlexAlignCenter = styled.div`
  display: flex;
  align-items: center;
`;

export const InfoSectionWarningText = styled(FlexJustifyCenter)`
  align-items: center;
  background-color: #FBECEB;
  color: #AD362B;
  min-height: 60px;
  max-height: 60px;
  margin: 25px 60px;
`;

export const InfoSectionPersonsWrapper = styled(FlexJustifyCenter)`
  grid-gap: 30px;
  margin: 40px 60px;
`;

export const InfoSectionPersonInfoWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 370px;
  background-color: #FFFFFF;
  box-shadow: 0px 15px 40px rgba(184, 184, 184, 0.2), 0px 5px 10px rgba(184, 184, 184, 0.05);
  border-radius: 5px;
`;

export const InfoSectionPersonInfoHeader = styled(FlexAlignCenter)`
  height: 50px;
  width: 100%;
  background-color: ${getColorForEnvironment()};
  border-radius: 4px 4px 0px 0px;
`;

export const InfoSectionPersonInfoItemWrapper = styled(FlexAlignCenter)`
  justify-content: space-between;
  height: 40px;
  width: 100%;
  border-top: 1px solid #DAD9D8;
`;

export const InfoSectionPersonInfoItem = styled.div`
  padding: 0px 25px;
`;