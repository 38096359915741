import styled from "styled-components";
import type {
  StyledGenericButtonProps,
} from "./types";
import { PrimaryButton , SecondaryButton} from "@stacc/flow-ui-components";

export const ButtonContainer = styled.div`
  display: flex;
  gap: 8px;
  justify-content: space-between;
  padding: 0 28px;
  cursor: pointer;
`;

export const buttonStyle = { width: "142px", height: "40px", borderRadius: "8px", fontWeight: 600, cursor: "pointer" };

export const StyledPrimaryButton = styled(PrimaryButton)`
${buttonStyle}
background-color: ${(props) =>
    props.disabled ? "#B8B8B8" : "#2969FF"};
`

export const StyledSecondaryButton = styled(SecondaryButton)`
${buttonStyle}
background-color: ${(props) =>
    props.disabled ? "#B8B8B8" : "transparent"};
`


export const StyledTabButton = styled.div`
  background-color: transparent;
  border: none;
  color: #1B4DF5
  padding: 8px;
  &:hover {
    cursor: pointer;
  };
  border-bottom: ${(props: any) =>
    props.selected && "2px solid blue"}; 
`;

export const TabContainer = styled.div`
  display: flex;
  padding: 0px var(--space-200, 16px);
  align-items: center;
  gap: var(--space-200, 16px);
  align-self: stretch;
  border-bottom: 1px solid var(--stroke-secondary, #d4dcec);
  background: var(--surface-default, #fff);
`;

export const StyledGenericButton = styled.div<StyledGenericButtonProps>`
  display: flex;
  width: 98px;
  border-radius: 8px;
  border: ${(props: any) => props.border};
  padding: 8px;
  color: ${(props: any) => props.color};
  justify-content: center;
  align-items: center;
  gap: 8px;
  font-weight: 600;
  line-height: "140%";
  cursor: pointer;
  &:hover {
    border-color: $(props: any) => props.BorderColorOnHover;
    color: $(props: any) => props.ColorOnHover;
    font-size: 14.2px;
  }
`;

export const StyledDownloadButton = styled.button`
  background-color: #ffffff;
  border: 1px solid #00000042;
  color: #000000;
  cursor: pointer;
  font-size: 12px;
  font-weight: 700;
  line-height: 2;
  padding: 6px 10px;
  border-radius: 4px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition: all 0.2s ease-in-out;
  &:hover {
    background-color: #000000;
    color: #ffffff;
  }
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
`;
