import styled from "styled-components";

export const StyledLink = styled.div`
    color: #1B4DF5;
    text-decoration: none;
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
    `;



export const CaseInfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    color: "#1e1e1e";
    `;



export const WarningContainerRect = styled.div`
    display: flex;
    justify-content: center;
    gap: 0.5rem;
    background-color: #FFF0CB;
    padding: 8px 8px;
    color: #9E3F00;
    border-radius: 8px;
    `;

export const PaddingContainer = styled.div`
    padding: 0 28px;
    `;

export const Title = styled.div`
        font-size: 14px;
        font-weight: bold;
        margin-bottom: 1rem;
        color: #1e1e1e;
        `;

export const LinkContainer = styled.div`
      display: flex;
      justify-content: space-between;
      gap: 1rem;
      padding: 10px 10px;
      align-items: center;
`;

export const LinkWrapper = styled.div`
      display: flex;
      gap: 1rem;
      margin: 0px 0.5rem;
`;

export const InfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

export const ApplicantInfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    `;

export const ApplicantName = styled.div`
    margin-top: 1rem;
    margin-left: 0.25rem;
    margin-bottom: 0.5rem;
    font-size: 14px;
    font-weight: 500;
    color: #1e1e1e;
    `;


export const ApplicantNameSpan = styled.span`
    color: #6e7686
    margin-left: 0.5rem;
    font-weight: 500;
    `;