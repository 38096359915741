import styled from "styled-components";
import PropTypes from "prop-types";
import { SecondaryButton, Icons } from "@stacc/flow-ui-components";
import { getAttachmentFile, getAttachmentMeta } from "../../services/attachment.service";
import downloadLink from "../../utils/downloadLink";

const DownloadButtonStyled = styled(SecondaryButton)`
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    padding-right: 1em;
  }
`;

interface DownloadAttachmentButtonProps {
  flowId: string;
  attachmentKey: string;
  title?: string;
}

const DownloadAttachmentButton = ({ flowId, attachmentKey, title = "Download" }: DownloadAttachmentButtonProps) => {
  const onDownloadClick = async (e: { preventDefault: () => void; }) => {
    e.preventDefault();

    try {
      const metaResult = await getAttachmentMeta(flowId, attachmentKey);
      const { data } = metaResult;
      const { attachment } = data;

      const result = await getAttachmentFile(flowId, attachmentKey);
      if (result?.data) {
        downloadLink(result.data, attachment.filename);
      }
    } catch (error) {
      console.error("Download failed", error);
    }
  };

  return (
    <DownloadButtonStyled onClick={onDownloadClick}>
      <Icons.FilePdf />
      {title}
    </DownloadButtonStyled>
  );
};

DownloadAttachmentButton.propTypes = {
  flowId: PropTypes.string.isRequired,
  attachmentKey: PropTypes.string.isRequired,
  title: PropTypes.string,
};


export default DownloadAttachmentButton;
