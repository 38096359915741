import { Modal } from "@stacc/flow-ui-components";
import ManualPepReview from "../../tasks/manual-pep-review";
import VideoIdView from "../../tasks/video-id";
import ManualApplicationReview from "../../tasks/manual-payout-approval";

interface ModalProps {
  flow: any;
  modalToShow: string;
  handleModalClose: () => void;
}
export const ModalTypes = {
  KYC: "kyc",
  VIDEO_ID: "videoId",
  MANUAL_PAYOUT_APPROVAL: "manualPayoutApproval",
}
export const Modals = ({ flow, modalToShow, handleModalClose }: ModalProps) => {
  const videoId = flow?.caseData?.data?.integrations?.electronicIdVideoId;
  const signatureId = flow?.caseData?.data?.integrations?.signatureId;
  const flowId = flow?.caseData?.flowId;
  switch (true) {
    case modalToShow === ModalTypes.KYC:
      return (
        <Modal
          title="Manual AML / Pep review"
          size="large"
          onClose={handleModalClose}
        >
          <ManualPepReview flow={flow} />
        </Modal>

      )
    case modalToShow === ModalTypes.VIDEO_ID:
      return (
        <Modal
          title="Video Id Data from electronic ID"
          size="large"
          onClose={handleModalClose}
        >
          <VideoIdView
            videoId={videoId}
            signatureId={signatureId}
            flowId={flowId}
          />
        </Modal>
      )
    case modalToShow === ModalTypes.MANUAL_PAYOUT_APPROVAL:
      return (
        <Modal
          title="Manual Payout Approval"
          size="large"
          onClose={handleModalClose}
        >
          <ManualApplicationReview flow={flow} />
        </Modal>
      )
    default:
      return null
  }
}