import styled from "styled-components";
// import { FormComponentContainer } from "../styles";
import type { FormNumberProps } from "./types";
import { LabelText } from "./styles";
import { FormComponentContainer } from "../style";

/**
 * Renders a form number.
 * @component
 * @param {Object} props - The component props.
 * - label - The text area label.
 * - name - The text area name.
 * - id - The text area id.
 * - onChange - The function to handle the text area change.
 * - onBlur - The function to handle the text area blur.
 * - value - The text area default value.
 * - disabled - Indicates if the text area is disabled.
 * - required - Indicates if the text area is required.
 * - size - The text area size.
 * @returns {JSX.Element} The rendered component.
 */
export const FormNumber = (props: FormNumberProps) => {
  function addSpacingToNumber(text: string) {
    // Check if the text is a number
    if (!Number.isNaN(text) && text.trim() !== "") {
      const formattedNumber = text
        .replace(/\s+/g, "") // Remove all whitespace
        .replace(/^0+(?!$)/, "") // Remove leading zeros, except if the string is "0"
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 "); // Add spaces between every 3 digits
      return formattedNumber;
    }
    return text;
  }

  function handleOnChange(e: string) {
    // Only allow numbers and spaces
    const regex = /^[0-9\s]*$/;
    if (regex.test(e)) {
      // Remove any spaces and update the value
      props.onChange(e.replace(/\s+/g, ""));
      return;
    }

  }
  const hasErrorStyle = props.hasError ? { border: "1px solid #EF4444" } : {};
  return (
    <FormComponentContainer>
      <LabelText>{props.label}</LabelText>
      <StyledInput
        style={
          props.customStyle
            ? { ...hasErrorStyle, ...props.customStyle }
            : hasErrorStyle
        }
        name={props.name}
        id={props.id}
        onChange={(e: any) => handleOnChange(e.target.value)}
        onBlur={props.onBlur}
        value={addSpacingToNumber(props.value.toString())}
        disabled={props.disabled}
        required={props.required}
        placeholder={props.placeholder}
      />
    </FormComponentContainer>
  );
};

const StyledInput = styled.input`
    border: 1px solid #B8C4E0;
    max-width: 100%;
    padding: 8px;
    border-radius: 5px;
    font-size: 14px;
    color: #1E1E1E;
`;
