import styled from "styled-components";

export const BorderBottom = styled.div<{ $hasPadding: boolean, $hasBorderBottom: boolean }>`
    border-bottom: ${(props) => props.$hasBorderBottom ? "1px solid #d4dcec" : "none"};
    display: flex;
    justify-content: space-between;
    padding: ${(props) => props.$hasPadding ? "0 10px" : "0"};
    align-items: flex-start;
    `;

export const InfoWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
    `;

export const WarningContainer = styled.div`
    display: flex;
    justify-content: center;
    gap: 0.5rem;
    align-items: center;
    background-color: #FFF0CB;
    padding: 0px 8px;
    border-radius: 12px;
    height: 24px;
    color: #9E3F00;
    `;

    export const InfoText = styled.div<{ $hasTextRight: boolean }>`
    margin: 0.5em;
    color: #1e1e1e;
    text-align: ${(props) => props.$hasTextRight ? "right" : "left"};
    `;