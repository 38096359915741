import { ApplicantInfo } from "./ApplicantInfo";
import { InfoRow } from "../../../components/InfoRow/InfoRow";
import { CaseInfoContainer } from "./style";
import { defaultNoDataText, type CaseDetailsProps, type CaseDetailsPropsSe } from "./types";

function getApplicationStatus(flow: any): string {
  const lastCallback = flow?.data?.lastCallback?.endpoint || "";
  const businessStatus = flow?.data?.businessStatus || "";
  return businessStatus ? `${lastCallback} (${businessStatus})` : lastCallback;
}

function isAddressMatch(flow: any, applicationInfo: any) {
  const valuesToCheck = ['streetName', 'streetNumber', 'postalCode', 'city', 'province'];
  const applicationAddress: any = applicationInfo?.addressInformation;
  const flowAddress: { [key: string]: string } | undefined = flow?.data?.agentApiApplication?.applicant?.addressInformation;
  if (!applicationAddress || !flowAddress) {
    return false;
  }
  return valuesToCheck.every((key) => applicationAddress[key] === flowAddress[key]);
}

export const CaseDetailsFormConfig = ({ flow, isSweden }: CaseDetailsProps) => {
  const appliedLoanAmount = flow?.data?.offer?.finalLoanAmount || defaultNoDataText;
  const accountNumber = (isSweden ? flow?.data?.agentApiApplication?.application?.bankAccountNumber : flow?.data?.agentApiApplication?.bankAccountNumber) || defaultNoDataText;
  const email = flow?.data?.agentApiApplication?.applicant?.emailAddress || defaultNoDataText;
  const phone = flow?.data?.agentApiApplication?.applicant?.mobileNumber || defaultNoDataText;

  const hasAddressInformation = flow?.data?.agentApiApplication?.applicant?.addressInformation;

  // get the address information for the flow
  const streetName = flow?.data?.agentApiApplication?.applicant?.addressInformation?.streetName;
  const streetNumber = flow?.data?.agentApiApplication?.applicant?.addressInformation?.streetNumber;
  const postalCode = flow?.data?.agentApiApplication?.applicant?.addressInformation?.postalCode;
  const city = flow?.data?.agentApiApplication?.applicant?.addressInformation?.city;
  const province = flow?.data?.agentApiApplication?.applicant?.addressInformation?.province;
  const addressString = hasAddressInformation ? `${streetName} ${streetNumber}, ${postalCode} ${city}, ${province}` : defaultNoDataText;


  return (
    <CaseInfoContainer>
      <InfoRow title="Applied loan amount" value={appliedLoanAmount} hasMatch={false} hasPadding={false} />
      <InfoRow title="Account number" value={accountNumber} hasMatch={false} hasPadding={false} />
      <InfoRow title="Email address" value={email} hasMatch={false} hasPadding={false} />
      <InfoRow title="Phone number" value={phone} hasMatch={false} hasPadding={false} />
      {hasAddressInformation && (<InfoRow title="Address" value={addressString} hasMatch={false} hasPadding={false} />)}
      <div style={{
        padding: "0px 10px"
      }}>
      </div>
    </CaseInfoContainer >
  )
}

export const CaseDetailsEs = ({ flow, showLoan, showMatch, applicationInfo }: CaseDetailsProps) => {
  const appliedLoanAmount = flow?.data?.offer?.finalLoanAmount || defaultNoDataText;
  const accountNumber = flow?.data?.agentApiApplication?.bankAccountNumber || defaultNoDataText;
  const email = flow?.data?.agentApiApplication?.applicant?.emailAddress || defaultNoDataText;
  const phone = flow?.data?.agentApiApplication?.applicant?.mobileNumber || defaultNoDataText;

  const hasAddressInformation = flow?.data?.agentApiApplication?.applicant?.addressInformation;

  // get the address information for the flow
  const streetName = flow?.data?.agentApiApplication?.applicant?.addressInformation?.streetName;
  const streetNumber = flow?.data?.agentApiApplication?.applicant?.addressInformation?.streetNumber;
  const postalCode = flow?.data?.agentApiApplication?.applicant?.addressInformation?.postalCode;
  const city = flow?.data?.agentApiApplication?.applicant?.addressInformation?.city;
  const province = flow?.data?.agentApiApplication?.applicant?.addressInformation?.province;
  const addressString = hasAddressInformation ? `${streetName} ${streetNumber}, ${postalCode} ${city}, ${province}` : defaultNoDataText;

  // Match flags
  const addressMatch = showMatch ? isAddressMatch(flow, applicationInfo) : false;
  const accountNumberMatch = showMatch ? accountNumber === applicationInfo?.accountNumber : false;
  const emailMatch = showMatch ? email === applicationInfo?.email : false;
  const phoneNumberMatch = showMatch ? phone === applicationInfo?.phone : false;
  return (
    <CaseInfoContainer>
      {!showLoan && (<InfoRow title="Application status" value={getApplicationStatus(flow)} hasMatch={false} hasPadding={!showLoan} />)}
      {showLoan && (<InfoRow title="Applied loan amount" value={appliedLoanAmount} hasMatch={false} hasPadding={!showLoan} />)}
      <InfoRow title="Account number" value={accountNumber} hasMatch={accountNumberMatch} hasPadding={!showLoan} />
      <InfoRow title="Email address" value={email} hasMatch={emailMatch} hasPadding={!showLoan} />
      <InfoRow title="Phone number" value={phone} hasMatch={phoneNumberMatch} hasPadding={!showLoan} />
      {hasAddressInformation && (<InfoRow title="Address" value={addressString} hasMatch={addressMatch} hasPadding={!showLoan} />)}
      <div style={{
        padding: "0px 10px"
      }}>
      </div>
    </CaseInfoContainer >
  )
}


export const CaseDetailsSe = ({ flow, showMatch, applicationInfo, coApplicantInfo }: CaseDetailsPropsSe) => {
  const appliedLoanAmount = flow?.data?.offer?.finalLoanAmount || defaultNoDataText;
  const accountNumber = flow?.data?.agentApiApplication?.bankAccountNumber || defaultNoDataText;
  const accountNumberMatch = showMatch ? accountNumber === applicationInfo?.accountNumber : false;
  const hasCoApplicant = !!flow?.data?.agentApiApplication?.coapplicant;

  const emails = [];
  const phones = [];

  emails.push(applicationInfo.email);
  if (coApplicantInfo) {
    emails.push(coApplicantInfo?.emailAddress);
  }
  phones.push(applicationInfo.phone);
  if (coApplicantInfo) {
    phones.push(coApplicantInfo?.mobileNumber);
  }

  const info = {
    email: emails,
    phone: phones,
  }

  return (
    <CaseInfoContainer>
      <InfoRow title="Application status" value={getApplicationStatus(flow)} hasMatch={false} hasPadding />
      {hasCoApplicant && (<InfoRow title="Applied loan amount" value={appliedLoanAmount} hasMatch={false} hasPadding />)}
      <InfoRow title="Account number" value={accountNumber} hasMatch={accountNumberMatch} hasPadding />
      <ApplicantInfo flow={flow} info={info} isMainApplicant hasCoApplicant={hasCoApplicant} />
      {hasCoApplicant && (<ApplicantInfo flow={flow} info={info} isMainApplicant={false} hasCoApplicant={hasCoApplicant} />)}
      <div style={{
        padding: "0px 10px"
      }}>
      </div>
    </CaseInfoContainer >
  )
}

