import { ApplicantInfo } from "../../../components/Applicant/ApplicantInfo";
import { FormNumber } from "../../../components/Input/FormNumber";
import { FormTextArea } from "../../../components/Input/FormTextArea";
import { CommonUserTaskNew } from "../CommonUserTaskNew";
import type { ComponentConfig } from "../types";
import { GapContainer, GridContainer, InfoContainer, InfoText, PaddingContainer } from "./style";
import { FormComponentContainer } from "../../../components/style";
import Accordion from "../../../components/Accordion/Accordion";
import { useState } from "react";
import { FormText } from "../../../components/Input/FormText";
import type { FormOptions, FormDataErrors, FormDataKeys, FormData } from "./types";
import { Icons } from "@stacc/flow-ui-components";

const formOptions: FormOptions[] = [
  { label: "Loan amount", value: "loanAmount" },
  { label: "Loan duration", value: "loanDuration" },
];

function isNumber(value: string) {
  return !Number.isNaN(Number(value));
}

const UpdateApplication = ({ flow, task, complete, save, trigger }: any) => {
  // Flow state
  const applicationData = flow?.data?.agentApiApplication;
  const offerData = flow?.data?.offer;

  const applicantInfo = applicationData?.applicant;
  const coApplicantInfo = applicationData?.coapplicant
    ? { ...applicationData.coapplicant, isCoApplicant: true }
    : null;

  const {
    finalLoanAmount,
    interestRate,
    maxLoanAmount,
    minLoanAmount,
    effectiveInterestRate,
    monthlyCost,
    startupFee,
    termFee,
    terms,
  } = offerData || {};

  const loadedAnswer = task?.data?.updateApplication ?? {
    loanAmount: finalLoanAmount,
    loanDuration: terms,
    interestRate: interestRate,
    comment: "",
  };

  // Local state
  const [formData, setFormData] = useState<FormData>(loadedAnswer);
  const [errors, setErrors] = useState<FormDataErrors>({});

  const leftSideDetails = [
    { label: "Final loan amount", value: finalLoanAmount },
    { label: "Interest rate", value: interestRate },
    { label: "Maximum loan amount", value: maxLoanAmount },
    { label: "Minimum loan amount", value: minLoanAmount },
  ];

  const leftSideIcon = finalLoanAmount ? (
    <Icons.SuccessCircle />
  ) : flow?.caseData?.status === "active" ? (
    <Icons.ActiveCircle />
  ) : (
    <Icons.AbortedCircle />
  );

  const rightSideDetails = [
    { label: "Startup fee", value: startupFee },
    { label: "Term fee", value: termFee },
    { label: "Terms", value: terms },
    { label: "Effective interest rate", value: effectiveInterestRate },
    { label: "Monthly cost", value: monthlyCost },
  ];

  function handleComplete() {
    // Validate the form and make sure we are sending in numbers
    const errors: FormDataErrors = {
      loanAmount: false,
      loanDuration: false,
      interestRate: false,
      comment: false
    };

    if (isNumber(formData.loanAmount) === false) {
      errors.loanAmount = true;
    }
    if (isNumber(formData.loanDuration) === false) {
      errors.loanDuration = true;
    }
    if (isNumber(formData.interestRate) === false) {
      errors.interestRate = true;
    }
    if (Object.values(errors).some((error) => error === true)) {
      setErrors(errors);
      return;
    }

    setErrors({});
    const numberLoanAmount = Number(formData.loanAmount);
    const numberLoanDuration = Number(formData.loanDuration);
    const numberInterestRate = Number.parseFloat(formData.interestRate);

    const validatedData = {
      loanAmount: numberLoanAmount,
      loanTerms: numberLoanDuration,
      loanInterestRate: numberInterestRate,
      comment: formData.comment,
    }
    trigger(
      validatedData,
      () => { },
      () => { },
    );
  };

  function handleSave() {
    save({ updateApplication: formData },
      () => { },
      () => { });
  };

  function handleFormUpdate(name: string, value: any) {
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  }

  const formConfig: ComponentConfig[] = [
    {
      component: (
        <GapContainer>
          <FormComponentContainer>
            <b>Application info</b>
          </FormComponentContainer>
          <ApplicantInfo applicant={applicantInfo} />
          {coApplicantInfo && <ApplicantInfo applicant={coApplicantInfo} />}
        </GapContainer>
      ),
      order: 1,
    },
    {
      component: (
        <GapContainer>
          {formOptions.map(({ label, value }: { label: string, value: FormDataKeys }) => (
            <FormNumber
              key={value}
              label={label}
              name={value}
              placeholder={`Enter ${label.toLowerCase()}`}
              value={formData[value]}
              onChange={(number) =>
                handleFormUpdate(value, number)
              }
              hasError={!!errors[value]}
            />
          ))}
          <FormText
            label="Interest rate"
            name="interest rate"
            onChange={(value: string) => handleFormUpdate("interestRate", value)}
            defaultValue={formData.interestRate}
            hasError={!!errors.interestRate}
          />
        </GapContainer>
      ),
      order: 2,
    },
    {
      component: (
        <FormTextArea
          label="Internal comment"
          name="comment"
          placeholder="Enter your comment here"
          onChange={(value: string) =>
            handleFormUpdate("comment", value)
          }
          defaultValue={formData.comment}
        />
      ),
      order: 5,
    },
  ];

  const infoConfig = [
    {
      component: (
        <Accordion title="Summary" startsExpanded hasChevron={false}>
          <PaddingContainer>
            <b>Application details</b>
            <GridContainer>
              <div className="left-side" style={{ marginTop: "1rem" }}>
                {leftSideDetails.map(({ label, value }) => (
                  <InfoContainer key={label}>
                    <InfoText>{label}</InfoText>
                    <InfoText>{value}</InfoText>
                  </InfoContainer>
                ))}
                <InfoContainer>
                  <InfoText>Offer given</InfoText>
                  <InfoText>{leftSideIcon}</InfoText>
                </InfoContainer>
              </div>
              <div className="right-side" style={{ marginTop: "1rem" }}>
                {rightSideDetails.map(({ label, value }) => (
                  <InfoContainer key={label}>
                    <InfoText>{label}</InfoText>
                    <InfoText>{value}</InfoText>
                  </InfoContainer>
                ))}
              </div>
            </GridContainer>
          </PaddingContainer>
        </Accordion>
      ),
      order: 1,
    },
  ];

  return (
    <CommonUserTaskNew
      handleComplete={handleComplete}
      handleSave={handleSave}
      infoConfig={infoConfig}
      formConfig={formConfig}
      overrideMasonryColumns={2}
    />
  );
};

export default UpdateApplication;
